* {
    box-sizing: border-box;
}

:root {
    @include media-breakpoint-down(md) {
        font-size: 14px;
    }
}

body {
    font-family: 'Nunito Regular';
}

a {
    text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins Bold';
}

.container-lg {
    max-width: 1728px;
    width: 90%;
    margin: 0 auto;
}

// Homepage
.section-title {
    text-align: center;
    padding: 2em 0;
    font-size: 2.6rem;
    margin: 0;
    @include media-breakpoint-down(md) {
        font-size: 2.2rem;
    }
}
