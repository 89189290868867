.section-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr 80% 1fr;
        // margin-bottom: 4em;
    }
    > a {
        @include media-breakpoint-down(sm) {
            grid-column: 1 / -1;
        }
    }
    .founders,
    .awards,
    .career {
        position: relative;
        overflow: hidden;
        padding: 0;

        // &::before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     background: linear-gradient(
        //         to top right,
        //         rgba($black, 0.15),
        //         rgba($black, 0)
        //     );
        //     z-index: 1;
        // }
        &:hover img {
            transform: scale(1.1);
        }
    }
    img {
        width: 100%;
        height: auto;
        transition: transform 300ms ease;
    }
    h2 {
        position: absolute;
        background: rgba($white, 0.9);
        width: 100%;
        z-index: 2;
        margin: 0;
        font-size: 1.7rem;
        padding: 0.8em 0;
        text-align: center;
        bottom: 0;
        color: $dark-grey;
    }
}
