.content {
    padding-bottom: 4em;
    h1 {
        padding: 1.5em 0;
    }
    h4 {
        margin-bottom: 1em;
        margin-top: 2em;
    }
    // ol,
    // p {
    //     margin-bottom: 1em;
    // }
}
