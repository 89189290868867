#news.page-header {
    @include bg-header('../assets/img/news/header-bg.jpg');
    margin-bottom: 4em;
}

.news-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    margin-bottom: 3em;
    grid-gap: 1rem;
    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }
    .news-card {
        background: $white;
        &__thumbnail {
            width: 100%;
            height: auto;
        }
        &__details {
            padding: 15px 20px;
            @include media-breakpoint-down(md) {
                padding: 12px 16px;
            }
            h6 {
                font-family: 'Poppins Regular';
                font-size: 0.7rem;
                margin-bottom: 0.8em;
            }
            a {
                display: inline-block;
                font-family: 'Poppins Medium';
                font-size: 0.9rem;
                line-height: 1.4;
                margin: 0;
                color: $dark-grey;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}

.pagination {
    font-family: 'Poppins Regular';
    .page-link {
        background: $white;
        border: none;
        color: $light-blue;
        padding: 0.8em 1.2em;
        transition: all 300ms ease;
        &:hover {
            background: $light-blue;
            color: $white;
        }
        &.disabled {
            background: $black;
        }
    }
}
