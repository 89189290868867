#group-profile.page-header {
    @include bg-header('../assets/img/group-profile/header-bg.jpg');
}

.intro {
    @include page-intro;
}

#group-profile {
    h1 {
        padding: 5rem 0;
    }

    .btn {
        background: $light-blue;
        color: $white;
        position: relative;
        left: 50%;
        margin-bottom: 4em;
        transform: translateX(-50%);
        &:hover {
            background: $dark-grey;
        }
    }
    .advantages {
        padding: 4em 0 0 0;
        text-align: center;
        font-family: 'Nunito Regular';

        @include d-grid-equal(auto-fit, 300px);
        grid-gap: 2rem;
        &-a,
        &-b,
        &-c {
            padding: 5em 0 3em 0;

            position: relative;
            h2 {
                margin-bottom: 0.6em;
                color: $light-blue;
            }
            img {
                height: 80px;
                margin-bottom: 2em;
            }
        }
    }
    .milestone {
        background: url('../assets/img/pattern-bg.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        padding-bottom: 100px;
    }
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        > .timeline-container {
            grid-column: 2 / span 1;
            @include media-breakpoint-down(sm) {
                grid-column: 1 / -1;
            }
        }
    }
    .timeline {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 2.5em;
        &:nth-of-type(odd) {
            padding-right: 3rem;
            left: -100%;
            text-align: right;
            &::before {
                display: inline-block;
                position: absolute;
                background: $white;
                content: '';
                top: 25px;
                right: -10px;
                height: 1.2rem;
                width: 1.2rem;
                border: 2px solid $light-blue;
                border-radius: 50%;
                z-index: 1;
            }
            &::after {
                content: '';
                border-right: 2px solid $light-blue;
                height: 100%;
                position: absolute;
                top: 0;
                right: -2px;
                z-index: 0;
            }
        }
        &:nth-of-type(even) {
            padding-left: 3rem;
            &::before {
                display: inline-block;
                position: absolute;
                background: $white;
                content: '';
                top: 25px;
                left: -8px;
                height: 1.2rem;
                width: 1.2rem;
                border: 2px solid $light-blue;
                border-radius: 50%;
                z-index: 1;
            }
            &::after {
                content: '';
                border-left: 2px solid $light-blue;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
        }
        @include media-breakpoint-down(sm) {
            &:nth-of-type(odd) {
                left: 0;
                text-align: left;
                padding-right: 0;
                padding-left: 3rem;
                &::before {
                    left: -7px;
                }
                &::after {
                    left: 0;
                    border-left: 2px solid $light-blue;
                    border-right: none;
                }
            }
        }

        &__date {
            font-size: 3rem;
            color: $light-blue;
            > span {
                font-family: 'Poppins Bold';
            }
        }
        &__info {
            .month {
                font-family: 'Nunito Bold';
            }
            img {
                display: block;
                margin-left: auto;
                width: 50px;
            }
        }
    }
}
.activities.d-grid-1 {
    // margin-top: 5em;
    // margin-bottom: 3em;
    @include d-grid-equal(auto-fit, 200px);
    @include media-breakpoint-down(lg) {
        @include d-grid-equal(2, 150px);
    }
    grid-auto-rows: auto;
    // .activities-a .content {
    //     background: url('../assets/img/our-founders/header-bg.jpg');
    // }
    // .activities-b .content {
    //     background: url('../assets/img/vision-mission/header-bg.jpg');
    // }
    // .activities-c .content {
    //     background: url('../assets/img/awards/header-bg.jpg');
    // }
    // .activities-d .content {
    //     background: url('../assets/img/news/header-bg.jpg');
    // }
    // .activities-e .content {
    //     background: url('../assets/img/responsibilities/header-bg.jpg');
    // }
    .activities-a,
    .activities-b,
    .activities-c,
    .activities-d,
    .activities-e {
        position: relative;
        overflow: hidden;
        &:hover {
            .content img {
                transform: scale(1.1);
            }
        }
        .content {
            position: relative;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 220px;
            width: auto;
            transition: all 300ms ease;
            padding-bottom: 0 !important;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 300ms ease;
            }
            h5 {
                position: absolute;
                background: rgba($white, 0.85);
                width: 100%;
                left: 0;
                bottom: 0;
                color: $dark-grey;
                padding: 0.8em 1em;
                // border-radius: 4px;
                display: inline-block;
                font-size: 1rem;
                // width: 100%;
                margin: 0;
                text-align: center;
            }
        }
    }
}
