#responsibilities.page-header {
    @include bg-header('../assets/img/responsibilities/header-bg.jpg');
    @include media-breakpoint-down(md) {
        margin-bottom: 3em;
    }
}

.responsibilities {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(50%, 1728px)) 1fr;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr 90% 1fr;
    }
    &.odd {
        .responsibilities__image {
            grid-column: 1 / span 2;
            @include media-breakpoint-down(md) {
                grid-column: 2 / span 1;
                margin-bottom: 2em;
            }
        }
        .responsibilities__content {
            grid-column: 3 / span 1;
            @include media-breakpoint-down(md) {
                grid-column: 2 / span 1;
            }
        }
    }
    &.even {
        .responsibilities__image {
            grid-column: 3 / span 2;
            order: 2;
            @include media-breakpoint-down(md) {
                grid-column: 2 / span 1;
                order: 1;
                margin-bottom: 2em;
            }
        }
        .responsibilities__content {
            grid-column: 2 / span 1;
            order: 1;
            @include media-breakpoint-down(md) {
                grid-column: 2 / span 1;
                order: 2;
            }
        }
    }
    &__image {
        width: 100%;
    }
    &__content {
        display: flex;
        padding: 0 3em;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-down(md) {
            display: block;
            padding: 0;
            margin-bottom: 3em;
        }
        h2 {
            font-size: 2.2rem;
            margin-bottom: 0.7em;
            @include media-breakpoint-down(lg) {
                font-size: 1.7rem;
            }
        }
        hr {
            padding: 1px;
            border: none;
            width: 60px;
            margin: 0;
            margin-bottom: 1.8em;
            border-radius: 100px;
            background: $light-blue;
            box-shadow: 0 0 10px 0 $light-blue;
        }
    }
}
