#our-founders.page-header {
    @include bg-header('../assets/img/our-founders/header-bg.jpg');
}

.founders {
    padding: 7em 0 4em 0;
    // .d-grid {
    //     @include d-grid-equal(auto-fit, 300px);
    //     grid-gap: 3rem;
    // }
    img {
        width: 100%;
    }
    .nav-tabs {
        border: 0;
        margin-bottom: 2em;
    }
    .tab-pane {
        background: $white;
        padding: 2em;
        h6 {
            color: $light-blue;
            margin-bottom: 1.5em;
        }
        p {
            margin: 0;
        }
    }
    .nav-item {
        margin-right: 2em;
        .content__details {
            padding: 1.5em;
            @include media-breakpoint-down(sm) {
                padding: 1em;
            }
            h4 {
                margin-bottom: 0.5em;
                @include media-breakpoint-down(sm) {
                    font-size: 1.2rem;
                    line-height: 1.5;
                }
            }
            h6 {
                margin-bottom: 1em;
                color: $light-blue;
                @include media-breakpoint-down(sm) {
                    font-size: 0.8rem;
                }
            }
            .btn {
                background: $light-blue;
                color: $white;
                padding: 8px 15px;
                box-shadow: none;
                border: none;
                &:focus {
                    background: $dark-grey;
                }
                @include media-breakpoint-down(sm) {
                    padding: 5px 10px;
                    font-size: 0.8rem;
                }
                &:hover {
                    background: $dark-grey;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 1em;
        }
    }
    .nav-link {
        cursor: default;
        padding: 0;
        color: $black;
        background: $white;
        // margin-right: 2em;
        border: none;
        // position: relative;
        // &.active {
        //     &::before {
        //         opacity: 0;
        //     }
        // }
        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     transition: all 300ms ease;
        //     background: rgba($black, 0.6);
        // }
        // &:last-child {
        //     padding-right: 0;
        // }
        // @include media-breakpoint-down(sm) {
        //     margin: 1em;
        // }
    }
    // &__details {
    //     padding: 1.5em;
    //     h3 {
    //         margin-bottom: 0.3em;
    //     }
    //     h6 {
    //         margin-bottom: 1.3em;
    //         color: $light-blue;
    //     }
    //     p {
    //         margin: 0;
    //         font-size: 15px;
    //         line-height: 1.55;
    //     }
    // }
}
