@font-face {
    font-family: 'Poppins Light';
    src: url('../assets/fonts/Poppins/Poppins-Light.ttf');
}
@font-face {
    font-family: 'Poppins Regular';
    src: url('../assets/fonts/Poppins/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'Poppins Medium';
    src: url('../assets/fonts/Poppins/Poppins-Medium.ttf');
}
@font-face {
    font-family: 'Poppins Bold';
    src: url('../assets/fonts/Poppins/Poppins-Bold.ttf');
}
@font-face {
    font-family: 'Nunito Regular';
    src: url('../assets/fonts/Nunito/Nunito-Regular.ttf');
}
@font-face {
    font-family: 'Nunito Light';
    src: url('../assets/fonts/Nunito/Nunito-Light.ttf');
}
@font-face {
    font-family: 'Nunito Bold';
    src: url('../assets/fonts/Nunito/Nunito-Bold.ttf');
}
@font-face {
    font-family: 'Playfair Regular';
    src: url('../assets/fonts/Playfair_Display/PlayfairDisplay-Regular.ttf');
}
@font-face {
    font-family: 'Playfair Bold';
    src: url('../assets/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf');
}
