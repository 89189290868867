.job-header.single {
    padding: 5em 0;
    margin-bottom: 3em;
    .tag {
        font-family: 'Poppins Medium';
        font-size: 0.8rem;
        color: $light-blue;
        margin-bottom: 1.5em;
        padding: 0.3em 1em;
        border: 1px solid $light-blue;
        border-radius: 100px;
        display: inline-block;
        transition: all 300ms ease;
        &:hover {
            background: $light-blue;
            color: $white;
        }
    }
    h1 {
        margin-bottom: 0.5em;
        @include media-breakpoint-down(md) {
            font-size: 1.8rem;
        }
    }
}
.job-details {
    h4 {
        margin-bottom: 0.6em;
    }
    .btn {
        background: $light-blue;
        color: $white;
        margin-bottom: 3em;
        &:hover {
            background: $dark-grey;
        }
    }
    .share-job {
        hr {
            padding: 1px 0;
            width: 40px;
            background: $light-blue;
            box-shadow: 0 0 10px 0 $light-blue;
            border: none;
            margin: 0 auto;
            margin-bottom: 1.5em;
        }
        a {
            display: inline-block;
            margin: 0 0.2em;
            background: $dark-grey;
            color: $light-grey;
            height: 2rem;
            width: 2rem;
            line-height: 2rem;
            border-radius: 50%;
            border: 1px solid transparent;
            transition: all 300ms ease;
            &:hover {
                background: $light-grey;
                color: $dark-grey;
                border: 1px solid $dark-grey;
            }
        }
    }
}
