.top-nav {
    height: $top-nav-height;
    background-color: $white;
    position: relative;
    box-shadow: 0 0 10px 0 rgba($black, 0.2);
    border-bottom: 0.5px solid rgba($black, 0.2);
    @include media-breakpoint-down(lg) {
        z-index: 100;
        position: sticky;
        top: 0;
    }
    .container-lg {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 10fr 1fr;
    }
    &__logo {
        height: 3.8rem;
        display: flex;
        align-items: flex-end;
    }
    &__menu {
        display: flex;
        justify-content: center;
        margin: 0;
        &-dropdown {
            &:not(.active):hover {
                .secondary-nav {
                    visibility: visible;
                    opacity: 1;
                    z-index: 200;
                }
                .top-nav__menu-primary {
                    color: $light-blue;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            background: $white;
            position: fixed;
            right: 0;
            width: 50%;
            top: 0;
            height: 100vh;
            display: block;
            z-index: 200;
            box-shadow: 0 0 15px 0 rgba($black, 0.2);
            transform: translateX(100%);
            transition: transform 300ms ease;
            .top-nav__menu-primary {
                color: $dark-grey;
            }
        }
        &-primary {
            line-height: $top-nav-height;
            display: block;
            padding: 0 1.2em;
            color: $dark-grey;
            font-family: 'Poppins Medium';
            font-size: 0.9rem;
            transition: all 300ms ease;
            &.active {
                @include media-breakpoint-down(lg) {
                    background: $light-grey;
                }
            }
            &:hover {
                @include media-breakpoint-down(lg) {
                    background: $light-grey;
                }
            }
            &.active {
                color: $light-blue;
            }
        }
    }
    .secondary-nav {
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 10;
        box-shadow: 0 10px 20px 0 rgba($black, 0.1);

        &.active {
            visibility: visible;
            opacity: 1;
        }
        a {
            display: block;
            padding: 1.2em;
            color: $dark-grey;
            font-family: 'Poppins Medium';
            font-size: 0.9rem;

            &.active {
                color: $light-blue;
            }
            &:hover {
                color: $light-blue;
            }
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &__utils {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        li {
            padding: 0 0.6em;
            border-right: 2px solid rgba($black, 0.2);
            &:last-child {
                border: none;
            }
            &.search-toggler {
                border: none;
            }
        }
        .search-toggler {
            .search-icon {
                height: 17px;
            }
        }
        .search-bar {
            visibility: hidden;
            opacity: 0;
            transition: all 300ms ease;
            z-index: 1000;
            &.is-show {
                visibility: visible;
                opacity: 1;
            }
            input {
                position: absolute;
                left: 0;
                background: $light-grey;
                top: 0;
                border: none;
                height: 100%;
                padding: 1.5em;
                width: 100%;
                color: $dark-grey;
                &:focus {
                    outline: none;
                }
            }
            .close-search {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 1.5em;
                z-index: 100;
                color: $dark-grey;
                img {
                    height: 15px;
                }
            }
        }
        li.active a {
            color: $light-blue;
        }
        button {
            background: transparent;
            border: none;
            color: $white;
            padding: 0 0.4em;
        }
        a {
            color: $dark-grey;
            font-family: 'Poppins Medium';
        }
    }
}

.bottom-nav {
    background-color: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 20;
    box-shadow: 0 10px 20px 0 rgba($black, 0.1);
    @include media-breakpoint-down(lg) {
        top: $top-nav-height;
    }
    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        overflow-x: auto;
        white-space: nowrap;

        -webkit-overflow-scrolling: touch;
        @include media-breakpoint-down(lg) {
            justify-content: flex-start;
        }

        &-secondary {
            display: block;
            padding: 1.2em;
            color: $dark-grey;
            font-family: 'Poppins Medium';
            font-size: 0.9rem;
            position: relative;
            transition: all 300ms ease;

            &:hover {
                // background-color: $white;
                color: $light-blue;
            }
            &.active {
                color: $light-blue;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: $light-blue;
                    border-top-left-radius: 2px;
                    border-top-right-radius: 2px;
                }
            }
        }
    }
}

$s: 3px;

.burger-wrapper {
    // background: pink;
    cursor: pointer;
    margin: auto;
    width: $s * 10;
    height: $s * 8;
    transition: all 300ms ease;
    position: absolute;
    transform: translateY(-45%);
    top: 45%;
    right: 30px;
    z-index: 100;

    .hamburger {
        background: $dark-grey;
        width: $s * 10;
        height: $s;
        position: relative;
        transition: background 10ms 300ms ease;
        transform: translateY($s * 4);
        border-radius: 100px;

        &:before,
        &:after {
            transition: top 300ms 350ms ease, transform 300ms 50ms ease;
            position: absolute;
            background: $dark-grey;
            width: $s * 10;
            height: $s;
            content: '';
            border-radius: 100px;
        }
        &:before {
            top: $s * -3;
        }

        &:after {
            top: $s * 3;
        }
    }
}

.menu-trigger:checked {
    ~ .burger-wrapper {
        transform: translate(calc(-50vw + 20px), -12px);
        .hamburger {
            background: transparent;
            &:after,
            &:before {
                transition: top 300ms 50ms ease, transform 300ms 350ms ease;
                top: 0;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    ~ .top-nav__menu {
        transform: translateX(0);
    }
}
