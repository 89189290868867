#haixiu.page-header {
    @include bg-header('../assets/img/products/header-haixiu.jpg');
    .container-lg {
        padding-left: 120px;
    }
    img {
        width: 70px;
        margin-bottom: 1.3em;
    }
    h1 {
        font-size: 4rem;
        padding: 0;
        // margin: 0 auto;
        margin-bottom: 0.2em;
        color: $dark-grey;
    }
    // hr {
    //     padding: 2px 0;
    //     border: none;
    //     background: $light-blue;
    //     box-shadow: 0 0 10px 0 $light-blue;
    //     width: 70px;
    //     margin: 1.5em auto;
    //     border-radius: 100px;
    // }
    h6 {
        font-family: 'Poppins Light';
        color: $dark-grey;
        margin: 0;
    }
}

.haixiu {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(50%, 1728px)) 1fr;
    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr 90% 1fr;
    }
    &__image {
        grid-column: 1 / span 2;
        width: 100%;
        @include media-breakpoint-down(sm) {
            grid-column: 1 / -1;
            margin-bottom: 2em;
        }
    }
    &__content {
        display: flex;
        padding: 0 3em;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .btn {
            background: $light-blue;
            color: $white;
            margin-top: 1em;
            &:hover {
                background: $dark-grey;
            }
        }
        @include media-breakpoint-down(sm) {
            display: block;
            padding: 0;
            margin-bottom: 3em;
            grid-column: 2 / span 1;
        }
        grid-column: 3 / span 1;
    }
}

.haixiu-video {
    @include videos('../assets/img/products/haixiu.jpg');
}
