.main-header {
    .carousel-indicators {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        li {
            width: 15px;
            height: 15px;
            border: none;
            border-radius: 50%;
            background: $white;
            margin: 0 5px;
            opacity: 1;
            transition: all 300ms ease;
            &.active {
                background: $light-blue;
                transform: scale(1.5);
            }
        }
    }
    .carousel-item {
        height: calc(100vh - #{$top-nav-height});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        // &:nth-child(1),
        // &:nth-child(2),
        // &:nth-child(3) {
        //     &::before {
        //         position: absolute;
        //         content: '';
        //         top: 0;
        //         bottom: 0;
        //         left: 0;
        //         right: 0;
        //         background: linear-gradient(
        //             to right,
        //             rgba($black, 0.15) 50%,
        //             rgba($black, 0)
        //         );
        //         z-index: -1;
        //         @include media-breakpoint-down(sm) {
        //             background: rgba($black, 0.2);
        //             // background: linear-gradient(
        //             //     to right,
        //             //     rgba($black, 0.3) 100%,
        //             //     rgba($black, 0)
        //             // );
        //         }
        //     }
        // }
        // &:nth-child(3)::before {
        //     background: rgba($black, 0.2);
        // }
        @include media-breakpoint-down(md) {
            * {
                transform: translateY(20px);
            }
        }

        .scienjoy-text {
            height: 4rem;
            margin-bottom: 25px;
            @include media-breakpoint-down(sm) {
                height: 3rem;
            }
        }
        h1 {
            font-size: 3.5rem;
            margin-bottom: 25px;

            @include media-breakpoint-down(sm) {
                font-size: 2.5rem;
            }
        }
        h5 {
            font-family: 'Poppins Light';
            margin-bottom: 1.8em;
            max-width: 50%;
            font-size: 1.1rem;
            line-height: 1.5;
            @include media-breakpoint-down(sm) {
                font-size: 1rem;
                max-width: 100%;
            }
        }
        .btn {
            font-family: 'Nunito Regular';
            padding: 0.7em 2.2em;
            background: $light-blue;
            color: $white;
            border: none;
            &:hover {
                background: $dark-grey;
            }
        }
    }
}
