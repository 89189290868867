.section-4 {
    // padding-bottom: 6em;
    .d-grid {
        display: grid;
        grid-auto-rows: max-content;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 1rem;
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, minmax(100px, 1fr));
        }
    }
    .news-card {
        background: $white;
        &__thumbnail {
            width: 100%;
            height: auto;
        }
        &__details {
            padding: 15px 20px;
            @include media-breakpoint-down(md) {
                padding: 12px 16px;
            }
            h6 {
                font-family: 'Poppins Regular';
                font-size: 0.7rem;
                margin-bottom: 0.8em;
            }
            a {
                display: inline-block;
                font-family: 'Poppins Medium';
                font-size: 0.9rem;
                line-height: 1.4;
                margin: 0;
                color: $dark-grey;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
    .btn {
        background: $light-blue;
        color: $white;
        position: relative;
        left: 50%;
        // margin-bottom: 4em;
        margin: 4em 0;
        transform: translateX(-50%);
        &:hover {
            background: $dark-grey;
        }
    }
}
