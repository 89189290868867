#job-list.page-header {
    @include bg-header('../assets/img/job-vacancy/header-bg.jpg');
    margin-bottom: 4em;
}

.job {
    background: url('../assets/img/pattern-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.job-list {
    &__filter {
        border-right: 1px solid rgba($black, 0.1);
        hr {
            margin-bottom: 2em;
        }
        .list-group {
            margin-bottom: 1.5em;
        }
        a {
            color: $dark-grey;
            &:hover {
                color: $light-blue;
                .list-group-item {
                    background: $white;
                }
            }
        }
        .list-group-item {
            transition: all 300ms ease;
        }
        .badge {
            background: $light-blue;
            color: $white;
            padding: 0.3em 0.6em;
        }
    }
    &__details {
        .list-group-item {
            font-size: 0.8rem;
            padding: 1.25em 1.5em;
            transition: all 300ms ease;
            border-left: none;
            border-right: none;
            background: rgba($white, 0.85);
            &:first-child {
                border-top: 0;
            }
            &:first-child,
            &:last-child {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            @include media-breakpoint-down(md) {
                font-size: 0.7rem;
            }
            h4 {
                transition: all 300ms ease;
                @include media-breakpoint-down(md) {
                    font-size: 1.1rem;
                }
            }
            &:hover {
                // background: $light-grey;
                h4 {
                    color: $light-blue;
                }
            }
            .employee,
            .available,
            .closed {
                font-family: 'Nunito Bold';
            }
            .employee {
                color: $light-blue;
            }
            .available {
                color: #34a500;
            }
            .closed {
                color: #f22b29;
            }
        }
        .pagination {
            // .page-item.active {
            // }
            .page-item.active {
                .page-link {
                    background: $light-blue;
                }
            }
            .page-link {
                background: $light-grey;
            }
        }
    }
}
