#contact.page-header {
    @include bg-header('../assets/img/contact/header-bg.jpg');
    margin-bottom: 5em;
}

.contact-wrapper {
    background: url('../assets/img/pattern-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.contact {
    display: grid;
    padding-bottom: 6em;
    grid-template-columns: 1fr 1.5fr;
    font-family: 'Poppins Regular';
    // box-shadow: 0 0 20px 0 rgba($black, 0.2);
    transition: all 300ms ease;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
    &__form {
        background-color: rgba($white, 0.85);
        border-left: 1px solid rgba($black, 0.1);
        padding: 2.5em;
        color: $dark-grey;
        width: 100%;
        .form-group {
            margin-bottom: 1.5em;
        }
        .form-control {
            border-radius: 0;
            background: transparent;
            // margin-bottom: 1.5em;
            outline: none;
            box-shadow: none;
            // border: 1.5px solid #2e2e2e;
            font-size: 0.9rem;
            color: $dark-grey;
            &:not(#inputMessage) {
                height: 40px;
            }
            &:focus {
                border: 1.5px solid $light-blue;
            }
        }
        .btn {
            border-radius: 0;
        }
    }
    &__details {
        padding: 2.5em;
        transition: all 300ms ease;

        h2 {
            margin-bottom: 0.7em;
        }
        hr {
            margin: 3rem 0;
        }
        .btn {
            // border-radius: 0;
            color: $white;
            display: block;
            background: $light-blue;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            // margin-bottom: 2em;
        }
        img {
            // display: none;
            visibility: hidden;
            opacity: 0;
            margin: 0 auto;
            height: 0;
            transition: all 300ms ease;
            margin-top: 2em;
            &.is-show {
                height: 100%;
                // display: block;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
