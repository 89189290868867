footer {
    font-size: 0.8rem;
    background: $white;
    box-shadow: 0 5px 30px 0 rgba($black, 0.1);
    a {
        color: rgba($dark-grey, 0.5);
        transition: color 300ms ease;
        &:hover {
            color: $dark-grey;
        }
    }
    ul a {
        margin-bottom: 8px;
    }
    a,
    span {
        display: inline-block;
    }

    .list-unstyled {
        margin: 0;
    }

    .footer {
        .with-logo {
            position: relative;
            background: url('../assets/icon/scienjoy-white-notext-5.svg');
            background-position: bottom right;
            background-repeat: no-repeat;
            width: 100%;
            @include media-breakpoint-down(md) {
                background: none;
            }
        }
        &__logo {
            height: 6rem;
            margin-bottom: 30px;
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
        }
        &__company-loc {
            margin-bottom: 50px;
            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }
        &__social {
            &__icon {
                font-size: 1.5rem;
                padding: 0 8px;
                &:hover {
                    color: $light-blue !important;
                }
            }
        }
        &__col-title {
            font-size: 1.5rem;
            color: $dark-grey;
            margin-bottom: 12px;
        }
        &__col-divider {
            padding: 1px;
            border: none;
            width: 35px;
            margin: 0;
            margin-bottom: 1.9em;
            border-radius: 100px;
            background: $light-blue;
            opacity: 0.7;
            box-shadow: 0 0 10px 0 $light-blue;
        }

        &__divider {
            padding: 0.5px;
            background: rgba($black, 0.2);
        }
    }
}
