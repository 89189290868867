.section-2 {
    padding-bottom: 8em;
    // background: url('../assets/icon/pattern-1.svg');
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    img {
        width: 100%;
    }
    .d-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr 80% 1fr;
            grid-row-gap: 2rem;
        }
    }
    .products {
        position: relative;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
            grid-column: 2 / span 1;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: linear-gradient(
                to top,
                rgba($black, 0.2),
                rgba($black, 0)
            );
        }
        > img {
            transition: transform 500ms ease;
        }
        &__desc {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 1.5em;
            color: $white;
            z-index: 2;
            @include media-breakpoint-down(md) {
                margin: 1.2em;
            }
            &-title {
                transition: transform 300ms ease;
                transform: translateY(55%);
                @include media-breakpoint-down(md) {
                    transform: translateY(0);
                }
                img {
                    width: 3.6rem;
                    margin-bottom: 1.2em;
                    @include media-breakpoint-down(md) {
                        width: 2.8rem;
                    }
                }
                h2 {
                    font-size: 2.2rem;
                    margin: 0;
                    margin-bottom: 0.7em;
                    @include media-breakpoint-between(md, lg) {
                        font-size: 1.5rem;
                    }
                }
            }
            &-content {
                visibility: hidden;
                opacity: 0;
                transform: translateY(100%);
                @include media-breakpoint-down(md) {
                    transform: translateY(0);
                    visibility: visible;
                    opacity: 1;
                }
                @include media-breakpoint-between(md, lg) {
                    font-size: 0.8rem;
                }
                transition: all 300ms ease;
                p {
                    margin-bottom: 2em;
                    font-size: 0.85rem;
                }
            }
            .btn {
                padding: 0.5em 2.6em;
                @include media-breakpoint-between(md, lg) {
                    font-size: 0.8rem;
                }
            }
        }
        &:hover .products__desc-content {
            transform: translateY(0);

            visibility: visible;
            opacity: 1;
        }
        &:hover .products__desc-title {
            transform: translateY(0);
            // h2 {
            //     margin-bottom: 0.7em;
            // }
        }
        &:hover > img {
            transform: scale(1.1);
        }
    }
}
