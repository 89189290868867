#awards.page-header {
    @include bg-header('../assets/img/awards/header-bg.jpg');
}

.intro {
    @include page-intro;
}

.swiper-awards {
    // overflow-x: auto !important;
    margin: 0 auto;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    padding-bottom: 3em;
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        width: 100%;
        img {
            max-height: 240px;
            width: auto;
            display: inline-block;
            margin-bottom: 0.8em;
            @include media-breakpoint-down(sm) {
                max-height: 120px;
            }
        }
        p {
            font-size: 0.9rem;
            margin: 0;
        }
    }
}
.swiper-button-next,
.swiper-button-prev {
    margin-top: -50px;
    height: 30px;
    width: 30px;
    background: none;
    outline: none;
}
.swiper-button-prev {
    left: -30px;
    @include media-breakpoint-down(sm) {
        left: -20px;
    }
}

.swiper-button-next {
    right: -30px;
    @include media-breakpoint-down(sm) {
        right: -20px;
    }
}
.swiper-pagination-awards {
    bottom: 0 !important;
    .swiper-pagination {
        &-bullet {
            width: 10px;
            height: 10px;
            margin: 0 4px !important;

            background: $dark-grey !important;
            &-active {
                transform: scale(1.5);
                transition: all 300ms ease;

                background: $light-blue !important;
            }
        }
    }
}
