// Page Header Template with Different Background Image

@mixin bg-header($url) {
    position: relative;
    height: 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: url($url);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    z-index: -2;
    @include media-breakpoint-down(sm) {
        background-position: 60% 0%;
    }
    h1 {
        position: relative;
        max-width: 50%;
        color: $dark-grey;
        font-family: 'Poppins Bold';
        padding: 0;
        padding-left: 120px;
        margin: 0;
        z-index: 0;
        font-size: 3.2rem;
        @include media-breakpoint-down(sm) {
            max-width: 80%;
            font-size: 2.7rem;
            padding-left: 0;
        }
    }
}

@mixin videos($url) {
    background: url($url);
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        padding: 10em 0;
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     display: block;
    //     background: rgba($black, 0.1);
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     z-index: 0;
    // }
    a {
        font-size: 1.6rem;
        font-family: 'Poppins Medium';
        color: $black;
        position: relative;
        z-index: 1;
        &:hover {
            .icon {
                background: $light-blue;
                border-color: $light-blue;
            }
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
        }
        .icon {
            display: inline-block;
            border: 2px solid $black;
            border-radius: 50%;
            height: 80px;
            width: 80px;
            text-align: center;
            line-height: 80px;
            margin: 0 auto;
            margin-bottom: 0.7em;
            transition: all 300ms ease;
            @include media-breakpoint-down(sm) {
                height: 55px;
                width: 55px;
                line-height: 55px;
            }
        }
        .play-icon {
            // font-size: 1.5rem;
            height: 30px;
            // height: 30px;
            position: relative;
            top: -3px;
            left: 3px;
            @include media-breakpoint-down(sm) {
                height: 20px;
            }
        }
    }
    // a {
    //     font-size: 1.6rem;
    //     font-family: 'Poppins Medium';
    //     color: $white;
    //     position: relative;
    //     z-index: 1;
    //     @include media-breakpoint-down(sm) {
    //         font-size: 1.2rem;
    //     }
    //     &:hover {
    //         .icon {
    //             background: $light-blue;
    //             border-color: $light-blue;
    //         }
    //     }
    // }

    .modal-content {
        background: transparent;
        border: none;
        .modal-header {
            border: none;
        }
        .close {
            color: $white;
            padding-right: 0;
        }
        .modal-body {
            video {
                box-shadow: none;
                outline: none;
                border: none;
            }
        }
    }
}

// Grid System

@mixin d-grid-equal($repeat, $min) {
    display: grid;
    grid-template-columns: repeat($repeat, minmax($min, 1fr));
}

@mixin page-intro {
    p {
        margin: 0;
    }
    padding: 3em 0;
    font-size: 1.125rem;
    text-align: center;

    font-family: 'Poppins Light';
    @include media-breakpoint-down(md) {
        margin-bottom: 3em;
    }
}

@mixin grid-center-last($columns) {
    $total_columns: $columns * 2;
    $span: $total_columns / $columns;
    $offset: $columns - 2;
    $nth-child: if($columns % 2 == 0, 'odd', 'even');

    display: grid;
    grid-template-columns: repeat($total_columns, 1fr);

    & > * {
        grid-column: span $span;

        &:nth-last-child(#{$offset}):nth-child(#{$nth-child}) {
            grid-column: 3 / span $span;
        }
    }
}
