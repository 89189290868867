#life-at-scienjoy.page-header {
    @include bg-header('../assets/img/life-at-scienjoy/header-bg.jpg');
    // @include media-breakpoint-down(sm) {
    //     margin-bottom: 3em;
    // }
}

.life-at-scienjoy {
    background: url('../assets/img/pattern-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    > div:nth-child(2) {
        padding-bottom: 5em;
    }
    h2 {
        padding: 2.5em 0;
    }

    .swiper-photo {
        max-width: 90%;
        // margin-right: auto;
        // margin-left: auto;
        width: 100%;
        height: auto;
        .swiper-slide {
            img {
                width: 100%;
                height: auto;
                // height: 500px !;
            }
            h5 {
                text-align: center;
                padding: 1.5em 0;
                background: $white;
                z-index: 5;
            }
        }
    }
    .slide-next,
    .slide-prev {
        margin-top: -10px;
    }
    .slide-prev {
        left: 0;
        @include media-breakpoint-down(sm) {
            left: 5px;
        }
    }
    .slide-next {
        right: 0;
        @include media-breakpoint-down(sm) {
            right: 5px;
        }
    }
    .d-grid {
        @include d-grid-equal(5, 110px);
        grid-auto-rows: 280px;
        grid-gap: 5px;
        @include media-breakpoint-down(md) {
            @include d-grid-equal(auto-fit, 230px);
            grid-auto-rows: 230px;
        }
        @include media-breakpoint-down(xs) {
            @include d-grid-equal(auto-fit, 180px);
            grid-auto-rows: 180px;
        }
        .perk-a,
        .perk-b,
        .perk-c,
        .perk-d,
        .perk-e,
        .perk-f,
        .perk-g,
        .perk-h,
        .perk-i {
            background-color: rgba($white, 0.85);
            // background: $white;
            // padding: 1.8em;
            transition: all 300ms ease;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 1em;
            color: $light-blue;
            &:hover {
                background: $light-blue;
                color: $white;
            }
            img {
                height: 60px;
                display: block;
                margin-bottom: 1.2em;
                @include media-breakpoint-down(sm) {
                    height: 30px;
                }
            }
            h4 {
                @include media-breakpoint-down(sm) {
                    font-size: 1.3rem;
                }
            }
        }
    }
}
