.news-content {
    padding: 2em 0;
    .container {
        // max-width: 850px;
        font-family: 'Nunito Regular';
        line-height: 1.6;
        p {
            margin-bottom: 2em;
        }
        a {
            color: $light-blue;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                display: block;
                height: 2px;
                width: 100%;
                bottom: -1px;
                left: 0;
                background: $light-blue;
            }
        }
        img {
            width: 100%;
            display: block;
            margin-bottom: 2em;
        }
    }
}

.news-nav {
    margin-top: 5em;
    .news-card {
        background: $white;
        &__thumbnail {
            width: 100%;
            height: auto;
            margin: 0 !important;
        }
        &__details {
            padding: 15px 20px;
            background: $light-grey;
            @include media-breakpoint-down(md) {
                padding: 12px 16px;
            }
            h6 {
                font-family: 'Poppins Regular';
                font-size: 0.7rem;
                margin-bottom: 0.8em;
            }
            a {
                display: inline-block;
                font-family: 'Poppins Medium';
                font-size: 0.9rem;
                line-height: 1.4;
                margin: 0;
                color: $dark-grey;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
    .prev,
    .next {
        h5 {
            margin-bottom: 1.5em;
        }
        flex-basis: 40%;
        @include media-breakpoint-down(sm) {
            flex-basis: 45%;
        }
        a {
            // text-decoration: none;
            // color: $dark-grey;
            &::before {
                display: none;
            }
        }
    }
}

.related-news {
    padding: 3em 0;
    h2 {
        padding-bottom: 1em;
    }
    .d-grid {
        display: grid;
        grid-auto-rows: max-content;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 1rem;
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, minmax(100px, 1fr));
        }
    }
    .news-card {
        background: $white;
        &__thumbnail {
            width: 100%;
            height: auto;
        }
        &__details {
            padding: 15px 20px;
            @include media-breakpoint-down(md) {
                padding: 12px 16px;
            }
            h6 {
                font-family: 'Poppins Regular';
                font-size: 0.7rem;
                margin-bottom: 0.8em;
            }
            a {
                display: inline-block;
                font-family: 'Poppins Medium';
                font-size: 0.9rem;
                line-height: 1.4;
                margin: 0;
                color: $dark-grey;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}
