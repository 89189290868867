#visionmission.page-header {
    @include bg-header('../assets/img/vision-mission/header-bg.jpg');
}

.intro {
    @include page-intro;
}

.brand-culture {
    @include d-grid-equal(auto-fit, 300px);
    grid-gap: 1rem;
    div {
        position: relative;
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($black, 0.2);
            z-index: 0;
        }
        h2 {
            position: absolute;
            bottom: 0;
            left: 50%;
            text-align: center;
            font-size: 1.5rem;
            transform: translateX(-50%);
            z-index: 1;
            color: $black;
            background: rgba($white, 0.85);
            padding: 0.8em 0;
            width: 100%;
            margin-bottom: 0;
        }
        img {
            width: 100%;
        }
    }
}

.values {
    padding: 3em 0 6em 0;
    background: url('../assets/img/pattern-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    > div {
        display: grid;
        grid-gap: 1em;
        @include d-grid-equal(auto-fit, 250px);
        @include media-breakpoint-down(sm) {
            @include d-grid-equal(auto-fit, 180px);
        }
        @include media-breakpoint-up(lg) {
            @include grid-center-last(4);
        }
        > div {
            background: rgba($white, 0.85);
            transition: all 300ms ease;
            &:hover {
                // box-shadow: 0 0 10px 0 rgba($black, 0.1);
                transform: translateY(-5px);
            }
        }
    }
    &-content {
        // background: pink;

        bottom: 0;
        z-index: 1;
        color: $dark-grey;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 1em;
        img {
            height: 60px;
            display: block;
            margin-bottom: 1.2em;
            @include media-breakpoint-down(sm) {
                height: 50px;
            }
        }

        h2 {
            margin-bottom: 0.7em;
            // background: $dark-grey;
            color: $light-blue;
            font-size: 1.4rem;
            // padding: 0.6em 1em;
        }
        p {
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
        }
    }
}
